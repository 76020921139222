<template>
	<div class="Site PageBox" style="margin:0 auto">
		<div id="demo-custom" class="clearfix">a</div>
	</div>
</template>
<script>
	import '@/assets/css/test.css';
	import platform from '@/share/js/platform/platform'
	import digg from '@/share/js/platform/digg'
	import facebook from '@/share/js/platform/facebook'
	import tumblr from '@/share/js/platform/tumblr'
	import reddit from '@/share/js/platform/reddit'
	import pinterest from '@/share/js/platform/pinterest'
	import googlePlus from '@/share/js/platform/googlePlus'
	import twitter from '@/share/js/platform/twitter'
	import whatsapp from '@/share/js/platform/whatsapp'
	import twitterFollow from '@/share/js/platform/twitterFollow'
	import sharrre from '@/share/js/jquery.sharrre'
	import $ from "jquery";
	export default {
		name: 'Login',
		components: {

		},
		props: [],
		data() {
			return {

			}
		},
		computed: {},
		watch: {

		},
		created() {
			this.isSubmit = false;


		},
		mounted() {
			var ta = $;
			var ee = $('#demo-custom')[0];
			var a = document.getElementById('demo-custom');
			// debugger
			// ee.innerText='tttaaa';
			this.loadShareButton();

		},
		activated() {

		},
		destroyed() {

		},
		methods: {
			loadShareButton(){
				var list = ['facebook', 'twitter', 'whatsapp','tumblr', 'reddit', 'pinterest','twitterFollow', 'googlePlus'];
				var tt = $('#demo-custom');
				tt.sharrre({
					share: {
						googlePlus: true,
						facebook: true,
						twitter: true,
						pinterest: true,
						tumblr: true,
						whatsapp:true,
						reddit: true,
						twitterFollow: true,
						delicious: true,
						stumbleupon: true,
						linkedin: true,
					},
					buttons: {
						facebook: {
							popup: {
								width: 1200,
								height: 900
							}
						},
						twitter: {},
						delicious: {},
						linkedin: {},
						pinterest: {},
						twitterFollow: {
							user: 'GarethBale11'
						}
					},
					url: 'http://sharrre.com',
					enableCounter: false,
					enableHover: false,
					template: function () {
						var content = '';
						for (var i in list) {
							var service = list[i];
							content += '<a class="' + service + '"><i class="fa fa-' + service + '"></i>'+service+'</a> ';
						}
						return content;
					}(),
					render: function (api, options) {
						for (var i in list) {
							var service = list[i];
							$(api.element).on('click', '.' + service, function () {
								api.openPopup(this.className);
							});
						}
					}

				});
			}
		}
	}
</script>
<style scoped>

</style>
